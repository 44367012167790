import { GFN } from '../globalFunctions';
import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { GiMoneyStack } from 'react-icons/gi';
import {FaTrashAlt} from 'react-icons/fa';
import Swal from 'sweetalert2';
import label from '../../config/label.json';


/* Captura as cobranças do assas no backend */
const RecuperarAssinatura = ({assas}) =>{

  const [res, setRes] = useState([]);
 

  useEffect(() => {
    const fetchData = async () => {
      const usersData = await GFN.getAssinaturaDados(assas.assinaturaID);
      setRes(usersData);
    };
    if (assas) {
      fetchData();
    }
  }, [assas]);



  if(!res && !res.cycle) return null;

  
  let recorrencia = '';
  switch(res.cycle){
     
    case 'MONTHLY':
      recorrencia = 'Mensal';
    break;

    case 'SEMIANNUALLY':
      recorrencia = 'Semestral';
    break;

    case 'QUARTERLY':
      recorrencia = 'Trimestral';
    break;

    case 'YEARLY':
      recorrencia = 'Anual';
    break;

   
  }
  

  return(
    <>
      <span tag='assinatura'>Assinante {recorrencia}</span>
      <span tag='valor'> <GiMoneyStack /> {res.value?.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</span>
    </>
  )

}




/* Transforma a data em milesegundos do usuario para listar em data convencional */
const DataCadastro = ({data}) =>{

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  let mes = data.getMonth() + 1;
  mes = mes.toString().padStart(2, '0');

  return(
    <div id='Data' className='data-main'>
      <input className='data-input' placeholder='Dia' maxLength='2'  defaultValue={data.getDate().toString().padStart(2, '0')}/> 
      <span className='data-separador'>/</span>
      <input className='data-input' placeholder='Mês' maxLength='2'  defaultValue={mes}/>
      <span className='data-separador'>/</span>
      <input className='data-input' placeholder='Ano' maxLength='4' defaultValue={data.getFullYear()}/>
    </div>
  )
}




/* Define o status a ser listado  */
const Status = ({status}) =>{
  

  let init = '';
  switch(status){

    case 0:
      init = 'Em Teste';
    break;

    case 1:
      init = 'Gratuito';
    break;

    case 2:
      init = 'Assinante';
    break;

    case 3:
      init = 'Bloqueado';
    break;

  }

  return(
    <select id='Status' defaultValue={init} >

      <option data="Em Teste" tag="0">Em Teste</option>
      <option data="Gratuito" tag="1">Gratuito</option>
      <option data="Assinante" tag="2">Assinante</option>
      <option data="Bloqueado" tag="3">Bloqueado</option>

    </select>
  )
}





/* Transforma a data em milesegundos */
const transformDate = (input) =>{

  const data = new Date(input[2].value, input[1].value - 1, input[0].value);

  return data.getTime();
}




/* Atualiza os dados do usuario no banco de dados */
const salvar = async(id, instancias, envioMassa, agendamento) =>{

  await Swal.fire({
    title: 'Atenção',
    text: 'Você realmente deseja atualizar esse usuário?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Sim',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Não'

  }).then((result) => {
    if (result.isConfirmed) {
      update();
    }
  })

  async function update(){

    const nome = document.querySelector('#Nome');
    const login = document.querySelector('#Login');
    const senha = document.querySelector('#Senha');
    const data = document.querySelector('#Data');
    const status = document.querySelector('#Status');
  
    const userUpdate = {
  
      nome: nome.value,
      login: login.value,
      senha: senha.value,
      dataCadastro: transformDate(data.querySelectorAll('input')),
      status: status.querySelector(`option[data="${status.value}"]`).getAttribute('tag'),
      modulos: {
        "envioMassa": envioMassa.current.checked,
        "instancias": instancias.current.value,
        "agendamento": agendamento.current.checked
      }
    
    }


    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("userUpdate", JSON.stringify(userUpdate));
    formdata.append("key", sessionStorage.getItem('key'));
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    let res = await fetch(`${label.backend}/api/updateUser`, requestOptions)
    .then(response => response.text())
    .then(result =>{return result})
    .catch(error => console.error('error', error));

    window.location.reload();
  }
  

}




/* Remove o usuario do banco de dados e do Assas */
const removerUsuario = async(id, assas)=> {

  await Swal.fire({
    title: 'Atenção',
    text: 'Você realmente deseja excluir esse usuario? ele será removido do banco de dados e apagado no ASSAS',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Sim',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Não'

  }).then((result) => {
    if (result.isConfirmed) {
      remove();
    }
  })

  async function remove(){

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("key", sessionStorage.getItem('key'));
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    let res = await fetch(`${label.backend}/api/removeUser`, requestOptions)
    .then(response => response.json())
    .then(result =>{return result})
    .catch(error => console.error('error', error));

    if(!assas){
      window.location.reload();
    }


    removeAssas(); 

  }



  async function removeAssas(){

    var formdata = new FormData();
    formdata.append("idClient", assas.userID);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    let res = await fetch(`${label.backend}/api/assas/removeClient`, requestOptions)
    .then(response => response.json())
    .then(result =>{return result})
    .catch(error => console.error('error', error));


    window.location.reload();
  }
}


const resetToken = async(id) =>{
     
  Swal.fire({
    title: 'Realmente deseja resetar o token?',
    cancelButtonText:'Não',
    cancelButtonColor:'var(--btnNot)',
    showCancelButton: true,
    confirmButtonText: 'Sim',
    confirmButtonColor:'var(--primaria)'
  }).then(async(result) => {
    if (result.isConfirmed) {
       
      await GFN.resetToken(id);

      GFN.alert('success', 'Token resetado!');

    } 
  })
  
}


export function CardUser({user, setCardUser}) {
   
  const modulos = JSON.parse(user.modulos);

  const instancias = useRef(null);
  const envioMassa = useRef(null);
  const agendamento = useRef(null);

  useEffect(()=>{

    if(modulos.instancias){
      instancias.current.value = modulos.instancias;
    }
   
    if(modulos.envioMassa){
      envioMassa.current.checked = true;
    }

    if(modulos.agendamento == true){
      agendamento.current.checked = true
    }


  },[]);


  //Realiza a abertura do ASSAS
  const openAssas = () => {
    const userID = JSON.parse(user.idAssas).userID;
    window.open(`https://www.asaas.com/customerAccount/show/${userID}`, '_blank');
  }


  const cardUser = (

    <div className='modal'>

      <div className='content-modal card-modal'>

        <div className='card-content'>

        <span className='modal-Close card-close' onClick={()=> setCardUser(false)}>&times;</span>

        <div className='card-view'>
          <span onClick={()=>removerUsuario(user.id, JSON.parse(user.idAssas))} className='trash-card'> <FaTrashAlt /> </span> 
          <img style={{width:"100px", borderRadius:"50%"}} src={user.picture || 'images/defaulUser.svg'}/>
          <span>{GFN.formatNumber(user.whatsapp)}</span>

          <span>User ID: {user.id}</span>

          <div className='card-ass'>
            {user.idAssas !== null?  <RecuperarAssinatura  assas={JSON.parse(user.idAssas)}/> : ''}  
          </div>

          <button style={{marginTop:'1rem'}} onClick={()=>resetToken(user.id)}>Resetar Token</button>

          <button style={{marginTop:'1rem'}} onClick={openAssas}>Abrir no ASSAS</button>

        </div>


        <div className='card-dados'>
          <div>
            <label>Nome</label>
            <input id='Nome' defaultValue={user.nome}/>
          </div>

          <div>
            <label>Login</label>
            <input id='Login' defaultValue={user.login}/>
          </div>

          <div>
            <label>Senha</label>
            <input id='Senha' defaultValue={user.senha}/>
          </div>

          <div>
            <label>Data Cadastro</label>
            <DataCadastro  data={new Date(parseInt(user.dataCadastro))}/>
          </div>

          <div>
            <label>Status</label>
            <Status status={user.status}/>
          </div>

          <div>
            <label>Afiliado</label>
            <input style={{cursor:'not-allowed'}} disabled defaultValue={user.afiliado != 'false'? user.afiliado : 'Usuário não cadastrado por afiliação'}/>
          </div>

          <div style={{gap:'20px', display:'flex', justifyContent:'flex-end'}}>
            <button style={{backgroundColor:'var(--btnNot)'}} onClick={()=> setCardUser(false)} >Cancelar</button> <button onClick={()=>salvar(user.id, instancias, envioMassa, agendamento)}>Salvar</button> 
          </div>
          
        </div>


        <div className='card-modulos'>

          <h2>Modulos</h2>

          <div>
            <span>Instancias</span>
            <label className='label-instancias'>
              <input type="Number" ref={instancias}/>
            </label>
          </div>

            
          <div>
            <span>Envio Em Massa</span>
            <label className="switch">
              <input type="checkbox" ref={envioMassa}/>
              <span className="slider round"></span>
            </label>
          </div>


          <div>
            <span>Agendamento</span>
            <label className="switch">
              <input type="checkbox" ref={agendamento}/>
              <span className="slider round"></span>
            </label>
          </div>

        </div>


        </div>

      </div>

    </div>

  );


  return cardUser;

}