import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/watools.css';
import reportWebVitals from './reportWebVitals';
import Login from './js/login/login';
import Painel from './js/painel/painel';
import Afiliados from './js/afiliados/afiliados';
import { GFN } from './js/globalFunctions';


GFN.root.render(
  <React.StrictMode>

  {
    JSON.parse(sessionStorage.getItem('session'))? <Painel/> : <Login/>
  } 

  
  </React.StrictMode>
);

reportWebVitals();


window.addEventListener('click', (event)=>{

  if (event.target.closest('[tag="header-nav"]') && !event.target.closest('[tag="nav-content"]')) {
    
    document.querySelector('[tag="header-nav"]')?.remove();
  }
  
})